import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import socket from "../../socket/socket";
import { useChatList, useUser } from "../../hooks/useStore";
import { Images } from "../../themes";
import {
  LoadChats,
  UpdateCurrentChat,
  UpdateMessage,
  UpdateOffline,
  UpdateOnline,
  UpdateIsNotification,
  ProfileView
} from "../../utils/api/actionConstants";
import { getData,saveData } from "../../utils/api/apiHelper";
import { useCustomNavigate } from "../../utils/hooks/useCustomNavigate";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import ConsumerTab from "../../components/ConsumerTab";
import { AppRoute } from "../../router/routerConstants";
import CreatorTab from "../../components/CreatorTab";
import { getHourMinute, getAmPm, getDate } from "../../utils/Common";


const ChatList = () => {
  const dispatch = useDispatch();
  const list = useChatList();
  const currentUser = useUser();
  const [NavigateTo] = useCustomNavigate();
  const [emailNotification, setEmailNotification] = useState(currentUser.isEmailNotification);
  const [phoneNotification, setPhoneNotification] = useState(currentUser.isPhoneNotification);

  const handlePhoneNotificationChange = (event) => {
    if(currentUser.mobileNumber){
      const value = !phoneNotification;
      setPhoneNotification(value);
      dispatch(
        saveData({
          action: UpdateIsNotification,
          data: {isPhoneNotification:value},
        })
      );
    }else{
      alert("We are redirecting you to update profile page. It seems you didn't update your phone number");
      NavigateTo(AppRoute.Cons_Edit_Profile)
    }
  };
  const handleEmailNotificationChange = (event) => {
    const value = !emailNotification;
    setEmailNotification(value);
    dispatch(
      saveData({
        action: UpdateIsNotification,
        data: {isEmailNotification : value},
      })
    );
   
  };
  useEffect(() => {
    dispatch(getData({ action: LoadChats }));
  }, []);

  useEffect(() => {
    if (typeof socket.connected !== undefined) {
      if (socket.connected) {
        socket.emit("sign-in", currentUser);
      } else {
        if (socket.connected === false) {
          socket.connect();
          socket.emit("sign-in", currentUser);
        }
      }
    }
    setupSocketListeners();
  }, []);

  const onClientDisconnected = () => {
    console.log(
      "Connection Lost from server please check your connection.",
      "Error!"
    );
  };

  /**
   * Established new connection if reconnected.
   */
  const onReconnection = () => {
    if (currentUser) {
      socket.emit("sign-in", currentUser);
      console.log("Connection Established.", "Reconnected!");
    }
  };

  const onMessageRecieved = (message) => {
    dispatch({ type: UpdateMessage, payload: message });
  };

  /**
   * Setup all listeners
   */

  const setupSocketListeners = () => {
    socket.on("message", onMessageRecieved.bind(this));
    socket.on("reconnect", onReconnection.bind(this));
    socket.on("disconnect", onClientDisconnected.bind(this));

    socket.on("online", (obj) => {
      dispatch({
        type: UpdateOnline,
        payload: {
          userId: obj.userId,
          currentUser: currentUser.id,
          current: obj.current,
        },
      });
    });

    socket.on("offline", (obj) => {
      dispatch({
        type: UpdateOffline,
        payload: {
          userId: obj.userId,
          currentUser: currentUser.id,
          current: obj.current,
        },
      });
    });
  };

  const getMessageUser = (v) => {
    if (v.firstUser && v.firstUser.id === currentUser.id) {
      return v.secondUser;
    }
    if (v.secondUser && v.secondUser.id === currentUser.id) {
      return v.firstUser;
    }
  };

  const isCreator = () => {
    return currentUser.role === "Creator";
  };

  const onMoveToChat = (v) => {
    dispatch({ type: UpdateCurrentChat, payload: v });
    
    NavigateTo(AppRoute.ChatMessage, false, { chat: v });
  };
  function isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  function isImage(url) {
    return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
  }
  function isVideo(url) {
    return /\.(mp4|mpeg4|mov|avi|flv|mkv)$/.test(url);
  }

  const showLastMessage = (message) => {
    if (isJson(message)) {
      const data = JSON.parse(message);
      return "" + data.currency + data.amount;
    }

    if (isImage(message)) {
      return "MD_IMAGE_VIDEO";
    }

    if (isVideo(message)) {
      return "MD_IMAGE_VIDEO";
    }

    return message;
  };
  const profileClkd = (id) => {
    dispatch({ type: "ResetProfile" });
    dispatch(
      saveData(
        {
          action: ProfileView,
          data: {
            userId: id,
          },
        },
        true
      )
    ).then((r) => {
      NavigateTo(`${AppRoute.Search_Profile}/${id}`);
    });
   
  };

  //   const isReadCss = {
  //     background: "#d7d7d7",

  //     // background: rgb(136 23 139);
  // color: "#fff",
  // borderRadius: 0px 10px 0px 10px,
  // padding: 1px 10px 1px 10px;
  // overflow: hidden;
  //   };

  return (
    <div className="wrapper">
      <Header />
      <div className="container">
        <div className="chat-box-wrap content-library my-folder">
          <h1>Chat List</h1>

          {(!isCreator()) && (<>
            <div className="my-ac-form-info notication-txt">
              <div className="form-inner-holder">
                <p>Your notifications will come from "nexusholdingsgroupllc@gmail.com" to protect your privacy. {/*Your text messages will come from  number "+18333246135".*/} We will never mention “Arouzed", we will only tell you "Friendly reminder, you have unread messages.</p>
            
              </div>
            </div>
            <div className="my-ac-form-info notification-switch for-consumer">
            <div className="hdr-switch">
              <h3>Email Notification : </h3> 
              <span className="btn-switch">			
                <input type="radio" id="emailOn" value="true" name="emailNotification" onChange={(e) => handleEmailNotificationChange(e)} checked={emailNotification} className="btn-switch__radio btn-switch__radio_yes" />
                <input type="radio" value="false" onChange={(e) => handleEmailNotificationChange(e)}   checked={!emailNotification}  id="emailOff" name="emailNotification" className="btn-switch__radio btn-switch__radio_no" />		
                <label htmlFor="emailOff" className="btn-switch__label btn-switch__label_no"><span className="btn-switch__txt">Off</span></label>
                <label htmlFor="emailOn" className="btn-switch__label btn-switch__label_yes"><span className="btn-switch__txt">On</span></label>
                
              </span>
            </div>
            
            <div className="hdr-switch">
              <h3>Text Notification : </h3> 
              <span className="btn-switch">			
                <input type="radio" id="phoneOn" value="true" name="phoneNotification" onChange={(e) => handlePhoneNotificationChange(e)} checked={phoneNotification} className="btn-switch__radio btn-switch__radio_yes" />
                <input type="radio" value="false" onChange={(e) => handlePhoneNotificationChange(e)}   checked={!phoneNotification}  id="phoneOff" name="phoneNotification" className="btn-switch__radio btn-switch__radio_no" />		
                <label htmlFor="phoneOff" className="btn-switch__label btn-switch__label_no"><span className="btn-switch__txt">Off</span></label>
                <label htmlFor="phoneOn" className="btn-switch__label btn-switch__label_yes"><span className="btn-switch__txt">On</span></label>
                
              </span>
            </div> 
            </div></>)}
          <div className="my-ac-form-info">
            <div className="form-inner-holder consumer-info-text mt-5">
            <p><b>
            {(isCreator())?
             "If you see a blank message here, it means your profile was viewed by that member and you can reach out to them first if you want.":
             "Here is where you can chat with creators and request custom content. Remember to check back as it can take women some time to respond."
            }
            </b></p>
            </div>
          </div>
          <div className="chat-items">
            {list &&
              list.length > 0 &&
              list.map((v, k) => {
                let chatUser = getMessageUser(v);
                return (
                  <div
                  className={
                    v.sender && v.sender !== currentUser.id && !v.isRead
                      ? "chat-list unreadMessage"
                      : "chat-list"
                  }
                    key={k}
                    onClick={(e) => onMoveToChat(v)}
                  >
                    <div className="chat-img">
                      <figure >
                        <img
                          src={
                            chatUser && chatUser?.profilePic != ""
                              ? chatUser?.profilePic
                              : (isCreator())?Images.profileMale :Images.default_profile
                          }
                        />
                      </figure>
                      <span className={v.online ? "online" : "offline"}></span>
                    </div>
                    <div className="chat-detail chat-middle">
                    <h3 
                        
                      >
                        {chatUser && chatUser.name}
                      </h3>
                      <p
                        
                        // style={
                        //   v.sender && v.sender !== currentUser.id && !v.isRead
                        //     ? isReadCss
                        //     : {}
                        // }
                      >
                        {showLastMessage(v.lastMessage) === "MD_IMAGE_VIDEO" ? (
                          <img src={Images.mediaIcon} alt="" />
                        ) : (
                          showLastMessage(v.lastMessage)
                        )}
                      </p>
                    </div>
                    <div className="chat-detail chat-time">
                      <p>{getDate(v.lastMessageAt)}</p>
                      <p>
                        {getHourMinute(v.lastMessageAt)}
                        {getAmPm(v.lastMessageAt)}
                      </p>
                    </div>
                  </div>
                );
              })}
          </div>

          {isCreator() ? (
            <CreatorTab activeTab={0} />
          ) : (
            <ConsumerTab activeTab={2} />
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ChatList;
